<template>
    <v-form class="wrapperForm" v-if="eecbMetaData">
        <v-row dense>
            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{ $t("Сведения_о_регистрации") }}</div>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label f-label-more-rows">
                                    {{ $t("Предмет_регулирования") }}
                                    <span>({{$t("каз._яз.")}})</span>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ eecbMetaData.Title['kk-KZ'] ? eecbMetaData.Title['kk-KZ'] : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label f-label-more-rows">
                                    {{$t("Предмет_регулирования")}}
                                    <span>({{$t("рус._яз.")}})</span>
                                </label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ eecbMetaData.Title['kk-KZ'] ? eecbMetaData.Title['ru-RU'] : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label">{{$t("Гос_орган-разработчик")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    <v-enterprise-chip
                                        v-if="eecbMetaData.DeveloperEnterpriseId"
                                        :name="getEnterpriseName(eecbMetaData.DeveloperEnterpriseId)"
                                    />
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label">{{$t("Место_принятия")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ eecbMetaData.ApprovalPlace ? eecbMetaData.ApprovalPlace.Value : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label">{{$t("Регион_действия")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ eecbMetaData.Region ? eecbMetaData.Region.Value : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="6" class="section-wrapper">
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{$t("Государственная_регистрация")}}</div>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label">{{$t("Орган_гос_регистрации")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    <v-enterprise-chip
                                        v-if="eecbMetaData.JudiciaryAuthorityId != '00000000-0000-0000-0000-000000000000'"
                                        :name="getEnterpriseName(eecbMetaData.JudiciaryAuthorityId)"
                                    />
                                    <template v-else>
                                        {{ $t("Не_указано") }}
                                    </template>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t("Номер_гос_регистрации")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    {{ eecbMetaData.JudiciaryNumber ? eecbMetaData.JudiciaryNumber : $t("Не_указано") }}
                                </div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                 <label class="f-label">{{$t("Дата_гос_регистрации")}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">
                                <div class="onlyReadData">
                                    <template v-if="eecbMetaData.JudiciaryDate">
                                        {{ eecbMetaData.JudiciaryDate | formattedDate }}
                                    </template>
                                    <template v-else>
                                        {{ $t("Не_указано") }}
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                <v-card 
                    class="cloud-file-list-box"
                    flat
                >
                    <v-card-text>
                        <div class="form-box-title">{{ $t("Информация") }}</div>
                        <div class="cloud-items-container">

                            <v-row 
                                class="cfl-group-wrap full-width-row"
                                no-gutters
                            >
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{ $t("О_принятии_акта") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10" class="cfl-group-items">
                                    <v-row 
                                        v-if="eecbMetaData.ApprovalDataList.length > 0"
                                        dense
                                    >
                                        <v-col 
                                            cols="12" sm="12" md="3"
                                            class="cfl-item-wrap"
                                            v-for="(item, index) in eecbMetaData.ApprovalDataList"
                                            :key="index"
                                        >
                                            <div 
                                                class="cfl-item"                                                
                                            >
                                                <div class="file-icon">
                                                    <i class="fas fa-file-alt dark-gray-color"></i>
                                                </div>
                                                <div class="cfl-info">
                                                    <div class="cfl-abs">
                                                        <div class="cfl-filename">
                                                            {{ getEnterpriseName(item.EnterpriseId) }} № {{ item.Number }}
                                                        </div>
                                                        <div>
                                                            <div class="cfl-fileinfo cfl-ellipsinfo">
                                                                <span>
                                                                    {{ item.Date | formattedDate }}
                                                                </span>                                          
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div v-else class="cfl-placeholder text-left pl-0" >{{ $t('Не_указано') }}</div>
                                </v-col>
                            </v-row>

                            <v-row 
                                class="cfl-group-wrap full-width-row"
                                no-gutters
                            >
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{ $t("О_публикации_акта") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10" class="cfl-group-items">
                                    <v-row 
                                        v-if="eecbMetaData.PublicationList.length > 0"
                                        dense
                                    >
                                        <v-col 
                                            cols="12" sm="12" md="3"
                                            class="cfl-item-wrap"
                                            v-for="(item, index) in eecbMetaData.PublicationList"
                                            :key="index"
                                        >
                                            <div class="cfl-item">

                                                <div class="file-icon">
                                                    <i class="fas fa-file-alt dark-gray-color"></i>
                                                </div>
                                                
                                                <div class="cfl-info">
                                                    <div class="cfl-abs">
                                                        <div class="cfl-filename">
                                                            {{ item.Source.Value }} № {{ item.IssueNumber }}
                                                        </div>
                                                        <div>
                                                            <div class="cfl-fileinfo cfl-ellipsinfo">
                                                                <span>
                                                                    {{ item.Date | formattedDate }}
                                                                </span>                                          
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </v-col>
                                    </v-row>
                                    <div v-else class="cfl-placeholder text-left pl-0" >{{ $t('Не_указано') }}</div>
                                </v-col>
                            </v-row>

                        </div> 
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>
    </v-form>
</template>

<script>
import sys from '@/services/system';
import { mapGetters } from 'vuex';

export default {
    name: "goverment-registration-tab",
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? null;
        }
    },
    computed: {
        ...mapGetters('actionsource', { dataSource: 'getDataSource' }),
        eecbMetaData() {
            return this.dataSource?.Data?.Object?.Document?.EECBActMetadata;
        },
    },
    data () {
        return {
            contractors: []
        }
    },
    methods: {
        getEnterpriseName(enterpriseId) {
            let contractor = this.contractors.find(x => x[0] === enterpriseId);
            return contractor ? contractor[1] : "";
        }
    },
    async created() {
        this.contractors = await this.$store.dispatch('references/getContractors');
    }
}
</script>